import React, { useEffect, useState, useLayoutEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import classnames from 'classnames'
import SEO from 'components/seo'
import Layout from 'layouts/layout'
import Provider from 'components/Provider'
import Header from 'components/Topic/Header'
import Footer from 'components/Topic/Footer'
import Panel from 'components/Topic/Panel'
import DS2021Banner, { CityTimesProps } from 'components/Topic/DS2021Banner'
import TopicCollapse from 'components/Topic/Collapse'
import TopicAgendaTab from 'components/Topic/AgendaTab'
import IntroduceSwiper from 'components/Topic/IntroduceSwiper'
import TicketsTab, { TicketsProps } from 'components/Topic/TicketsTab'
import LogoWall from 'components/Topic/LogoWall'
import speakersData from 'data/ds2021/ds2021Speakers'
import { isBrowser } from 'utils/utils'

import 'swiper/swiper-bundle.css'
import { Container } from './index.module.less'

interface SpeakersProps {
  name?: string
  position?: string
  introduce?: string
  imgLink?: string
  smallImgLink?: string
  buttonText?: string
}

interface DS2021Props {
  data?: any
}

const DS2021: React.FC<DS2021Props> = (params) => {
  const { data } = params
  const [agendeData, setAgendeData] = useState<any[]>([])
  const [speakers, setSpeakers] = useState<SpeakersProps[]>([])
  const [tickets, setTickets] = useState<TicketsProps[]>([])
  const [cityTimes, setCityTimes] = useState<CityTimesProps[]>([])
  const [partnersLogoWall, setPartnersLogoWall] = useState<any>()
  const [mediaLogoWall, setMediaLogoWall] = useState<any>()
  const [speakersSpread, setSpeakersSpread] = useState(false)
  // 重定向到http://localhost:8000/topic/ds2021Review.html
  useLayoutEffect(() => {
    if (isBrowser()) {
      window.location.replace('/topic/ds2021Review.html')
    }
  }, [])
  useEffect(() => {
    setAgendeData(data?.allDs2021AgendaYaml?.nodes)
    setSpeakers(speakersData as SpeakersProps[])
    setTickets(data?.allDs2021TicketsYaml?.nodes)
    setCityTimes(data?.allDs2021CitysTimeYaml?.nodes)
    setPartnersLogoWall(data?.allDs2021PartnersYaml?.nodes)
    setMediaLogoWall(data?.allDs2021MediaYaml?.nodes)
  }, [])
  return (
    <></>
    // <Layout>
    //   <Provider>
    //     <div className={Container}>
    //       <SEO
    //         title="神策数据｜2021数据驱动大会｜新认知新力量"
    //         description="第六届 神策数据驱动大会，汇聚全球数字化代表人物，业内精英，各领域数字化企业代表齐聚北京，从新的世界格局观察未来大数据领域发展机遇，重新认知数据的力量！"
    //         keywords="2021数据驱动大会,第六届数据驱动大会,神策数据用户驱动大会,神策数据驱动大会"
    //         saTitle="首页-2021数据驱动大会"
    //       >
    //         {<script>window.location.replace('/topic/ds2021Review.html')</script>}
    //       </SEO>
    //       <Header />
    //       <main>
    //         <DS2021Banner className="banner-wrap" dataSource={cityTimes} />
    //         <Panel className="panel-dhld" type="primary" title="大会亮点" enTitle="HIGHLIGHTS">
    //           <TopicCollapse />
    //         </Panel>
    //         <Panel type="primary" title="大会议程" enTitle="AGENDA">
    //           <TopicAgendaTab className="topic-agenda-tap--wrap" dataSource={agendeData}></TopicAgendaTab>
    //         </Panel>
    //         <Panel
    //           id="speakers"
    //           className={classnames('speakers-panel', { 'is-spread': speakersSpread })}
    //           type="primary"
    //           title="嘉宾阵容"
    //           enTitle="SPEAKERS"
    //         >
    //           <IntroduceSwiper
    //             className="every-same-content"
    //             dataSource={speakers}
    //             onSpread={(value) => setSpeakersSpread(value)}
    //           />
    //         </Panel>
    //         <Panel id="tickets" className="tickets-panel" type="primary" title="参会门票" enTitle="TICKETS">
    //           <div className="tickets-wrap">
    //             <TicketsTab dataSource={tickets} />
    //           </div>
    //         </Panel>
    //         {/* <Panel type="primary" title="小数点 IP 专区" enTitle="IP">
    //         </Panel> */}
    //         <Panel type="primary" title="往届回顾" enTitle="PREVIOUS">
    //           <div className="previous-list">
    //             <a
    //               className="previous-01"
    //               target="_blank"
    //               href="https://www.sensorsdata.cn/topic/newds2020/ds2020review.html"
    //             >
    //               <StaticImage
    //                 src="../../../assets/images/topic/ds2021/previous-01.png"
    //                 alt="banner-title"
    //                 className="previous-img"
    //               />
    //             </a>
    //             <a
    //               className="previous-02"
    //               target="_blank"
    //               href="https://www.sensorsdata.cn/topic/newds2019/ds2019review.html"
    //             >
    //               <StaticImage
    //                 src="../../../assets/images/topic/ds2021/previous-02.png"
    //                 alt="banner-title"
    //                 className="previous-img"
    //               />
    //             </a>
    //             <a
    //               className="previous-03"
    //               target="_blank"
    //               href="https://www.sensorsdata.cn/topic/newds2018/ds2018review.html"
    //             >
    //               <StaticImage
    //                 src="../../../assets/images/topic/ds2021/previous-03.png"
    //                 alt="banner-title"
    //                 className="previous-img"
    //               />
    //             </a>
    //             <a className="previous-04" target="_blank" href="https://www.sensorsdata.cn/topic/ds2017/">
    //               <StaticImage
    //                 src="../../../assets/images/topic/ds2021/previous-04.png"
    //                 alt="banner-title"
    //                 className="previous-img"
    //               />
    //             </a>
    //           </div>
    //         </Panel>
    //         {/* <Panel type="primary" title="合作伙伴" enTitle="PARTNERS">
    //           <LogoWall logoList={partnersLogoWall}/>
    //         </Panel> */}
    //         <Panel type="primary" title="合作媒体" enTitle="MEDIA ">
    //           <LogoWall logoList={mediaLogoWall} />
    //         </Panel>
    //       </main>
    //       <Footer />
    //     </div>
    //   </Provider>
    // </Layout>
  )
}

export const query = graphql`
  query {
    allDs2021CitysTimeYaml {
      nodes {
        city
        leftButtonText
        ticketLink
        ticketLinkMb
        rightButtonText
        liveLink
        liveLinkMb
        countdownBegins
        openTime
      }
    }
    allDs2021TicketsYaml(sort: { order: ASC, fields: index }) {
      nodes {
        cityName
        index
        isStart
        ticketList {
          discountsPrice
          originalPrice
          timeList
          title
          link
          linkMb
          sellOut
          buttonText
        }
      }
    }
    allDs2021AgendaYaml(sort: { order: ASC, fields: index }) {
      nodes {
        cityName
        isStart
        dateList {
          date
          buttonText
          buttonLinkPc
          buttonLinkMb
          times {
            title
            list {
              endTime
              startTime
              title
              guestsList {
                name
                position
              }
            }
          }
        }
      }
    }
    allDs2021MediaYaml {
      nodes {
        title
        file {
          childImageSharp {
            gatsbyImageData(formats: PNG)
          }
        }
      }
    }
    allDs2021PartnersYaml {
      nodes {
        title
        file {
          childImageSharp {
            gatsbyImageData(formats: PNG)
          }
        }
      }
    }
  }
`

export default DS2021
