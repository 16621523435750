// 2021总览页-嘉宾阵容

const data = [
  {
    imgLink: require('assets/images/topic/ds2021/speakers/shenmi.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/shenmi-small.png').default,
    name: 'Philip Kotler',
    position: 'The Father of Modern Marketing',
    introduce: 'He is the S.C.Johnson Distinguished Professor of International Marketing at the Kellogg School of Management (emeritus). He received his M.A. degree in economics (1953) from the University of Chicago and his Ph.D. degree in economics (1956) from M.I.T. He has received honorary degrees from 22 universities. He is author of 80 books and 150 journal articles.',
    videoUrl: '',
    videoPoster: '',
    buttonText: ''
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/sangwenfeng.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/sangwenfeng-small.png').default,
    name: '桑文锋',
    position: '神策数据创始人 & CEO',
    introduce: '畅销书《数据驱动：从方法到实践》作者，湖畔五期学员。浙江大学计算机系硕士，在百度任职 8 年，从无到有构建了百度用户日志大数据平台，任大数据部技术经理。2015 年 4 月离职并创建神策数据，提供大数据分析和营销科技相关产品与服务，致力帮助中国企业实现数字化经营。',
    videoUrl: '',
    videoPoster: '',
    buttonText: ''
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/caojiang.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/caojiang-small.png').default,
    name: '曹犟',
    position: '神策数据联合创始人 & CTO',
    introduce: '清华大学计算机硕士，2008 年至 2014 年就职于百度大数据部，担任百度用户数据仓库（BUDW）技术负责人、资深研发工程师，参与数据收集与传输、数据建模、海量数据处理、数据应用、数据分析、ID-mapping 等各个环节的数据处理工作。2015 年 4 月至今作为神策数据联合创始人并担任神策数据 CTO 职务。',
    videoUrl: '',
    videoPoster: '',
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/maji.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/maji-small.png').default,
    name: '马骥',
    position: '小米集团全球互联网业务总经理',
    introduce: '2013 年加入小米，目前负责小米集团全球互联网业务，服务来自 220 个国家和地区的 4.54 亿用户。率领团队打造的多业务线支撑下的互联网生态是公司商业和利润的坚实保障。曾是 MIUI 产品总负责人、小米互联网商业化推动者、小米系统安全创建者和负责人。2017 年起负责国际互联网业务，快速成为小米集团增长最快的业务之一。领导研发的短视频产品 zili 一年内日活破千万，为印度市场排名第二的短视频产品。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/zhangduo.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/zhangduo-small.png').default,
    name: '张铎',
    position: '神策数据首席架构师',
    introduce: '清华大学计算机硕士，长期从事开源软件的开发与维护。2015-2019 年历任 Apache HBase 项目的 Committer、PMC 成员、主席和 Branch Committer。2020 年成为 Apache 软件基金会的 Member。2018 年，在 Apache 软件基金会全球近 7000 名 Committer 中，贡献数量排名第三。曾任小米开源委员会主席，负责小米整体开源工作的规划与推进。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/xumeiling.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/xumeiling-small.png').default,
    name: '徐美玲',
    position: '神策数据咨询服务总监',
    introduce: '浙江大学硕士，11 年互联网行业经验。负责公司咨询服务与解决方案规划、华南业务中心以及银行保险行业等业务板块，在数据体系搭建、数据分析、用户及市场研究、产品规划与设计、敏捷迭代实践、数字化运营体系建设等方面，有着丰富的实战经验及独到的方法论沉淀。曾就职于阿里巴巴集团、网易游戏和 PPmoney，具备产品规划与迭代管理等实战经验。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/zhangyijia.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/zhangyijia-small.png').default,
    name: '张一甲',
    position: '科技智库甲子光年创始人 & CEO',
    introduce: '毕业于北京大学数学科学学院，同获经济学双学位；曾获中国数学奥林匹克金牌，入选国家集训队；笔名“甲小姐”，科技行业头部 KOL；2017 年入选福布斯亚洲 30 Under 30、福布斯中国 30 Under 30；2018 年获得“2018ECI 年度商业创新新锐人物”奖项；中国新闻网《改革开放再出发》专题纪录片首集主人公。以甲子光年为中国科技产业智库，致力于推动中国科技产业化、产业科技化发展。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/zhenzhen.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/zhenzhen-small.png').default,
    name: '耿珍珍',
    position: '泰康在线数字创新总监',
    introduce: '华中科技大学，基础数学硕士，先后任职于美团，平安健康、众安保险和众安科技。10 年互联网大数据+保险科技实践经验，作为大数据专家，打造过多款大数据商业化产品及解决方案；在保险业数字化转型，数字化营销，客户价值挖掘和大数据风控等方面有丰富经验。曾多次为银保监会等专业机构提供咨询及服务。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/linyizhe.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/linyizhe-small.png').default,
    name: '林怡哲',
    position: '环球网校智能化学习线负责人',
    introduce: '现任环球网校智能化产品与效能提升负责人，曾就职于鼎捷科技。有丰富的在线教育学习平台建设及智能化产品成功操盘经验。在公司数据化转型、数据赋能战略落地、学习用户动态化实时监测、教学体系及教学原理等方面有较为深入的研究。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/hewen.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/hewen-small.png').default,
    name: '何雯',
    position: '石基信息市场部总经理',
    introduce: '现任石基信息市场部总经理，历任浩华管理顾问公司、港中旅酒店有限公司、STR Global 等机构管理职位，具有十余载酒店及旅游业从业经验，对营销管理、市场洞察、品牌管理、技术趋势等领域有着深刻的理解及洞察。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/fengzhihua.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/fengzhihua-small.png').default,
    name: '丰志华',
    position: '值得买科技数据中心负责人',
    introduce: '毕业于清华大学，硕士学位。历任京东战略 & 投资分析师，值得买科技战略分析师、数据分析负责人、BI 负责人等。具有数据体系建设，数据驱动业务的丰富经验。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/Flora.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/Flora-small.png').default,
    name: 'Flora',
    position: '某科技公司高级数据产品经理',
    introduce: '毕业于南京邮电大学软件工程专业，曾就职火币、作业帮等公司。具有数据行业多年实战经验，擅长交易所 & 区块链链上分析领域，全面理解数据工作视角，从解决一个数据具体问题到独立提供一套解决方案。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/jiwei.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/jiwei-small.png').default,
    name: '季炜',
    position: '直客通酒店集团业务部总经理',
    introduce: '毕业于中山大学本科市场营销专业，从事互联网酒店行业 20 年，曾带领团队建立和运营中国最大的酒店 B2B 分销 SAAS “房掌柜”，任职副总经理；月度活跃客户超过 5 千家，年度酒店客房预定近 500 万间夜，GMV 超 30 亿；2016 年加入北京直客通科技有限公司，先后组建华南业务团队，酒店集团业务团队。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/Kim.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/Kim-small.png').default,
    name: 'Eojini Kim (金贤)',
    position: '锦泓集团商城事业部副总经理',
    introduce: '在中国从事零售行业 10 余年，目前担任锦泓集团商城事业部副总经理职务。负责统筹管理整个集团新零售孵化平台业务，曾结合线下零售及社交营销做出新零售项目独立模型。在率领电商业务同比增长 250% 的同时，从无到有完成了整个集团新零售工作方式的打造。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/anhaolei.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/anhaolei-small.png').default,
    name: '安浩磊',
    position: '锅圈供应链（上海）有限公司 联合创始人 副总裁',
    introduce: '就任于锅圈供应链副总裁职务，负责锅圈云信息中心、大数据分析及技术开发、数字化供应链建设等，并基于 AI+BI 智能补货、协同系统等优化全链生产效率。截至目前，锅圈食汇全国门店超过 7000 家，覆盖了 21 省 3 个直辖市，200 个地级市，1000 多个县城，累计服务 1.3 亿家庭。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/kangfang.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/kangfang-small.png').default,
    name: '康芳',
    position: '豌豆公主 数据负责人',
    introduce: '西安电子科技大学毕业，豌豆公主数据负责人，曾就职于 360，具有 8 年丰富的互联网行业经验，从零开始构建了豌豆公主大数据平台。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/guanhainan.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/guanhainan-small.png').default,
    name: '关海南',
    position: '神策数据营销引擎技术负责人',
    introduce: '负责运营计划、流程画布、通道平台化等相关技术工作。北京邮电大学硕士，9 年大数据相关工作经验，擅长数据营销及大数据平台建设。曾任职百度大数据部研发工程师，参与开发大数据实时传输系统、日志标准化系统、离线计算引擎、交互查询引擎等；前金山云金融云大数据首席架构师，负责过私有云以及公有云大数据平台建设，上线多家大型客户。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/guxiaoxi.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/guxiaoxi-small.png').default,
    name: '谷小溪',
    position: '神策数据资深咨询专家',
    introduce: '17+ 年咨询服务与行业经验，上海财经大学工商管理（中外合作）专业，中欧商学院 Global EMBA。有德勤 IPO 审计咨询服务经验，并曾担任国际品牌酒店管理公司财务营运副总裁，负责大数据平台搭建等工作，对数据化方案与运营业务融合有丰富的实践经验。目前主要负责数字化解决方案落地。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/zhanghejin.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/zhanghejin-small.png').default,
    name: '张何津',
    position: '神策数据咨询专家',
    introduce: '上海交通大学硕士，8 年交通银行工作经验，曾担任交行信用卡中心互联网业务部线上运营团队负责人，负责千万级用户活跃的 App 运营。对于 App 平台运营策略和客户经营策略有丰富的实践经验，包括 App 平台流量运营、广告位运营、产品运营、用户运营、渠道运营、活动运营、内容运营等运营体系的搭建，以及获客、活跃、交易等业务目标的制定拆解、以及市场运营活动的规划执行。此外，对于信用卡核心业务和银行业务线上化转型有战略认知和经营经验。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/yangning.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/yangning-small.png').default,
    name: '杨宁',
    position: '神策数据证券基金行业数据分析负责人',
    introduce: '10 余年证券公司运营管理经验，上海交通大学 EMBA。曾主导运营中国证券史上第一个互联网证券产品，是将互联网技术、场景与券商经纪业务及财富管理相结合的运营实践者。现任神策咨询专家，帮助证券基金行业客户进行精细化运营管理，建设数字化财富管理转型体系、自动化运营体系，曾服务海通证券等多家头部券商。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/yixiangwen.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/yixiangwen-small.png').default,
    name: '易向文',
    position: '神策数据业务售前部北京分部负责人',
    introduce: '8+ 年工作经验，湖南大学计算机科学与技术 & 金融双学士，具备丰富的行业大数据基础建设、场景落地应用经验。目前担任神策业务售前部北京分部负责人，负责泛金融领域解决方案相关工作，从售前咨询到定制化方案设计到项目交付落地，曾服务多家消费金融行业头部客户。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/chenshijian.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/chenshijian-small.png').default,
    name: '陈世键',
    position: '神策数据数据分析部深圳分部 Leader',
    introduce: '华南师范大学应用心理学硕士毕业，10+ 年 TMT 行业经验，对于数理统计、算法分析、用户研究与市场分析均有丰富的实践经验与见解。曾就职于唯品会、PPmoney 等知名公司，带领团队多次实操用户洞察、价值方案产生、A/B 测试以及效果复盘的数据驱动式迭代，给平台业务带来持续增长。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/lihongbo.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/lihongbo-small.png').default,
    name: '李泓波',
    position: '神策数据业务售前部深圳分部负责人',
    introduce: '7+ 年工作经验，武汉大学硕士背景，曾为多家知名企业提供数字化营销升级咨询及落地服务。现任神策数据咨询专家，主要负责互联网、泛文娱行业数字化运营咨询项目的规划和落地，为资讯内容、社交直播、品牌零售等头部企业提供精细化运营体系建设、数据洞察与决策优化、增长策略设计方案并交付实施等服务。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/liqichao.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/liqichao-small.png').default,
    name: '李其超',
    position: '神策数据高级咨询专家',
    introduce: '8 年工作经验，北京航空航天大学信息工程专业，对于数据驱动精细化运营有丰富的落地和实践经验。曾就职于字节跳动、滴滴等担任高级产品经理，负责营销策略中台的建设和市场、运营人员支持等工作。目前负责神策营销云相关解决方案工作，为老乡鸡等餐饮企业设计定制化的方案。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/xuhuan.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/xuhuan-small.png').default,
    name: '徐缓',
    position: '神策数据数据治理研发负责人',
    introduce: '毕业于电子科技大学，软件工程、信息工程专业，毕业后联合创立造数科技并担任 CTO，带领技术团队完成了海量数据云采集产品、ETL 平台系统以及二级市场大数据情报系统的搭建。后加入神策数据，主导公司数据治理产品的设计与研发、推动技术解决方案的迭代，从零搭建了数据治理产品，对多种业务场景下的数据全生命周期治理有着丰富理解。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/guoguohui.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/guoguohui-small.png').default,
    name: '郭国辉',
    position: '神策数据保险行业资深咨询专家',
    introduce: '14 年以上保险业从业经验，先后就职于上海耀诚软件（N22)、众安在线、众安科技、横琴人寿，为 30 余家保险机构提供过中大型项目解决方案和咨询服务。在横琴人寿期间负责互联网平台建设与数字化营销创新模式探索，对险企线上平台搭建、数字化营销、线上平台运营有着深刻理解。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/yanhan.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/yanhan-small.png').default,
    name: '颜含',
    position: '神策数据咨询专家',
    introduce: '8+ 年行业从业经验，中国科学院硕士背景，在用户体验、推荐策略、内容生态等领域有丰富的实践经验及行业洞察。曾就职于快手、小米、360，负责过快手国际化、小米短视频、小米金融、360 手机卫士等多项业务。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/huzhiqiang.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/huzhiqiang-small.png').default,
    name: '虎志强',
    position: '神策数据咨询专家',
    introduce: '北京理工大学硕士毕业，7+ 年工作经验。熟悉开源大数据技术栈，取得量化评估模型相关专利 6 项。对大数据营销中台、数据仓库、CRM、数据化运营有深入研究，《在线教育大数据营销平台实战》系列文章作者。 曾任职于环球网校，带领团队完成数据中台和数据应用系统建设，建立产品实施机制，推动公司数据化运营战略落地。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/lixiaoming.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/lixiaoming-small.png').default,
    name: '李晓明',
    position: '神策数据资深咨询专家',
    introduce: '20 余年汽车行业经验，在 CRM 系统设计、产品设计、分销系统设计等上有丰富的实践经验。毕业于北京工商大学，技术背景，曾就职于联合利华、宝马、英菲尼迪等公司。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/wangxiaoman.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/wangxiaoman-small.png').default,
    name: '王晓曼',
    position: '神策数据资深数据分析师',
    introduce: '参与了滔搏运动、全棉时代、屈臣氏、雅士利、钱大妈等数据赋能业务设计。对于数据如何在企业内部为业务赋能有丰富实践经验与见解。加入神策之前，在天虹子公司灵智数科任职，先后设计超市扫码购、自助收银、百货客流、数据中台等系统，并通过数据持续迭代产品及运营落地，给前台经营和后台效率带来持续增长。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/zhongbingzhe.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/zhongbingzhe-small.png').default,
    name: '钟秉哲',
    position: '神策数据咨询专家',
    introduce: '6+ 年工作经验，庞培法布拉大学经济学硕士，曾在优信集团、贝壳集团等知名公司任职，负责大数据分析和用户增长工作，带领团队从底层平台搭建到数据策略落地的数据驱动闭环。现任神策数据咨询专家，负责神策用户标签画像体系与精细化运营的解决方案的设计与交付，为客户提供业务价值方案和工具平台落地的交付服务，助力客户业务增长。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/tangyuxuan.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/tangyuxuan-small.png').default,
    name: '唐予轩',
    position: '神策数据资深分析师',
    introduce: '对于用户增长，媒体渠道质量评估，用户归因追踪有丰富的实践经验与见解。曾就职于美股上市互联网房产平台，主导实施集团层面数字化营销数据评估体系搭建与落地服务。现任神策数据资深分析师，主要负责为客户提供数字化营销数据监测和互联网渠道效果广告评估咨询方案。曾从 0 到 1 搭建公司渠道评估和框架体系，用更加精确的数字化营销方式助力企业完成线上用户增长。'
  },
  {
    imgLink: require('assets/images/topic/ds2021/speakers/chenjinfeng.png').default,
    smallImgLink: require('assets/images/topic/ds2021/speakers/chenjinfeng-small.png').default,
    name: '陈金凤',
    position: '神策数据高级咨询专家',
    introduce: '上海交通大学硕士毕业，16+ 年工作经验。在神策主要负责为客户提供业务数据诊断，以及业务策略咨询，擅长通过数据洞察业务场景，挖掘数据价值，开展策略研究，发现业务增长点，帮助客户实现数据驱动业务发展。曾任职于中国电信、两鲜商贸、顶新集团等公司，带领团队主导实施了多个项目的数据指标体系搭建以及产品整体规划和方案设计。'
  },
];

export default data;
